import * as _ from 'lodash'
import { getFieldValidity } from './viewer-utils'

export enum ErrorName {
  FetchError = 'FetchError',
  NetworkError = 'NetworkError',
  RegistrationError = 'RegistrationError',
  UploadFileError = 'UploadFileError',
  UploadSignatureError = 'UploadSignatureError',
  FieldValidity = 'FieldValidityError',
}

export type FormError =
  | FieldValidity
  | FetchError
  | UploadFileError
  | UploadSignatureError
  | NetworkError
  | RegistrationError

export class FieldValidity extends Error {
  constructor({ fields }) {
    super(getFieldValidity(fields))

    this.name = ErrorName.FieldValidity
  }
}

export class FetchError extends Error {
  public readonly status
  public readonly data

  constructor({ endpoint, status, message }) {
    super(`Failed to fetch ${endpoint} with status code ${status}`)

    this.name = ErrorName.FetchError
    this.status = status
    this.data = message
  }
}

export class UploadFileError extends Error {
  public readonly data

  constructor(error) {
    const errorDescription = _.get(error, 'errorDescription')
    super(`Failed to upload file${errorDescription ? `: ${errorDescription}` : ''}`)

    this.name = ErrorName.UploadFileError
    this.data = error
  }
}

export class UploadSignatureError extends Error {
  public readonly data

  constructor(error) {
    super('Failed to upload signature')

    this.name = ErrorName.UploadSignatureError
    this.data = error
  }
}

export class NetworkError extends Error {
  constructor({ endpoint }) {
    super(`Failed to fetch ${endpoint}`)

    this.name = ErrorName.NetworkError
  }
}

export class RegistrationError extends Error {
  public readonly data

  constructor(message, data?) {
    super(message)

    this.name = ErrorName.RegistrationError
    this.data = data
  }
}
